// src/BaselineControlEvaluationForm.js
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Snackbar,
  Alert,
  Box,
} from '@mui/material';
import { updateBaselineControlEvaluationField } from './redux/actions/complianceActions';
import baselineControlEvaluationCriteria from './baselineControlEvaluation.json';
import { debounce } from 'lodash';

const BaselineControlEvaluationForm = ({
  baselineControlId,
  modelId,
  assessmentUuid,
  handleSaveOperation,
}) => {
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();
  const savedEvaluationData = useSelector(
    (state) =>
      state.compliance?.baselineControlEvaluations?.[baselineControlId] || {}
  );
  const [currentEvaluationData, setCurrentEvaluationData] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setCurrentEvaluationData(savedEvaluationData);
  }, [savedEvaluationData]);

  const saveData = useMemo(
    () =>
      debounce(async (data) => {
        setSaving(true);
        try {
          const response = await fetch(
            '/.netlify/functions/saveBaselineControlEvaluation',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ modelId, assessmentUuid, baselineControlId, data }),
            }
          );
          if (!response.ok)
            throw new Error('Failed to save baseline control evaluation');
        } catch (error) {
          console.error('Error saving baseline control evaluation:', error);
        } finally {
          setSaving(false);
        }
      }, 500),
    [modelId, assessmentUuid, baselineControlId]
  );

  const handleFieldChange = (fieldName, value) => {
    const updatedData = { ...currentEvaluationData, [fieldName]: value };
    setCurrentEvaluationData(updatedData);
    dispatch(updateBaselineControlEvaluationField(baselineControlId, fieldName, value));
    handleSaveOperation(() => saveData(updatedData));
  };

  const toTitleCase = (str) =>
    str
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

  const renderField = (field, fieldName) => {
    const fieldValue = currentEvaluationData[fieldName] || '';

    switch (field.fieldType) {
      case 'select':
        return (
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>{toTitleCase(fieldName)}</InputLabel>
            <Select
              label={toTitleCase(fieldName)}
              name={fieldName}
              value={fieldValue}
              onChange={(e) => handleFieldChange(fieldName, e.target.value)}
            >
              {field.options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 'text':
      case 'date':
      case 'file':
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type={
              field.fieldType === 'file'
                ? 'file'
                : field.fieldType === 'date'
                ? 'date'
                : 'text'
            }
            name={fieldName}
            value={fieldValue}
            onChange={(e) => handleFieldChange(fieldName, e.target.value)}
            InputLabelProps={
              field.fieldType === 'date' ? { shrink: true } : undefined
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <form
      style={{
        padding: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Tabs
        value={tabValue}
        onChange={(e, newValue) => setTabValue(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
      >
        {baselineControlEvaluationCriteria.map((attribute, index) => (
          <Tab
            key={index}
            label={
              <Typography variant="subtitle2" fontWeight="bold">
                {toTitleCase(attribute.attribute)}
              </Typography>
            }
          />
        ))}
      </Tabs>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', mt: 2 }}>
        {baselineControlEvaluationCriteria.map((attribute, index) => (
          <Paper
            key={index}
            elevation={0}
            hidden={tabValue !== index}
            sx={{ margin: '16px 0', padding: '16px' }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Field Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Value
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attribute.properties.map((field) => (
                  <TableRow key={field.name}>
                    <TableCell>
                      <Typography variant="subtitle2" fontWeight="bold">
                        {toTitleCase(field.name)}
                      </Typography>
                    </TableCell>
                    <TableCell>{renderField(field, field.name)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ))}
      </Box>
      <Snackbar open={saving} autoHideDuration={6000}>
        <Alert severity="info">Saving changes...</Alert>
      </Snackbar>
    </form>
  );
};

export default BaselineControlEvaluationForm;