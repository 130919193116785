// src/ActionPlan.js

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// MUI + Icons
import {
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Save as SaveIcon,
  ListAltOutlined as ListAltOutlinedIcon,
  DeleteOutline as DeleteIcon,
  Restore as RestoreIcon,
  Close as CloseIcon,
  SmartToyOutlined as SmartToyOutlinedIcon, // Newly added
} from '@mui/icons-material';
import {
  Box,
  Paper,
  Button,
  Typography,
  Badge,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Drawer,
  Divider,
  Collapse,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Fade,
} from '@mui/material';

// DnDKit
import {
  DndContext,
  useDroppable,
  useDraggable,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
  DragOverlay,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

// Redux Actions
import {
  fetchControls,
  setSavedControls,
  fetchSavedControlEvaluations,
  addSavedControl,
  discardControl,
  editGeneratedControl,
  editSavedControl
} from './redux/actions/controlActions';

// Helpers
import IconWithProgress from './IconWithProgress';
import AutosaveIndicator from './AutoSaveIndicator';
import useSaveOperation from './useSaveOperation';
import ControlEvaluationForm from './ControlEvaluation';

/** =========================
 *   Floating Trash Button
 ========================= **/
const FloatingTrashButton = ({ trashCount, onClick }) => {
  const { isOver, setNodeRef } = useDroppable({ id: 'discardedControls' });

  return (
    <Tooltip title="View discarded controls" arrow placement="left">
      <Badge
        ref={setNodeRef}
        badgeContent={trashCount}
        color="error"
        max={99}
        overlap="circular"
        sx={{
          '& .MuiBadge-badge': {
            fontSize: '0.75rem',
            height: '22px',
            minWidth: '22px',
            padding: '0 4px',
            top: '0px',
            right: '-5px',
          },
        }}
      >
        <IconButton
          onClick={onClick}
          sx={{
            backgroundColor: isOver ? 'error.light' : 'background.paper',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'action.hover',
              transform: 'scale(1.1)',
            },
            transition: 'transform 0.2s ease-in-out',
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Badge>
    </Tooltip>
  );
};

/** =========================
 *   Floating Save Button
 ========================= **/

const FloatingSaveButton = ({
  viewMode,
  onToggle,
  riskSpecificCount,
  savedCountForRisk,
}) => {
  const { isOver, setNodeRef } = useDroppable({ id: 'floatingSave' });

  const isGenerated = viewMode === 'generated';

  // If we are in "generated" mode, we show a Save icon + the savedCount
  // If we are in "saved" mode, we show a SmartToy icon + the generatedCount
  const icon = isGenerated ? <SaveIcon /> : <SmartToyOutlinedIcon />;
  const tooltip = isGenerated
    ? 'Switch to Saved Controls'
    : 'Switch to Generate Controls';

  const badgeNumber = isGenerated ? savedCountForRisk : riskSpecificCount;

  return (
    <Tooltip title={tooltip} arrow placement="left">
      <Badge
        ref={setNodeRef}
        badgeContent={badgeNumber}
        color="primary"
        max={99}
        overlap="circular"
        sx={{
          '& .MuiBadge-badge': {
            fontSize: '0.75rem',
            height: '22px',
            minWidth: '22px',
            padding: '0 4px',
            top: '0px',
            right: '-5px',
          },
        }}
      >
        <IconButton
          onClick={onToggle}
          sx={{
            backgroundColor: isOver ? 'primary.light' : 'background.paper',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: 'action.hover',
              transform: 'scale(1.1)',
            },
            transition: 'transform 0.2s ease-in-out',
          }}
        >
          {icon}
        </IconButton>
      </Badge>
    </Tooltip>
  );
};

/** =========================
 *   Trash Modal
 ========================= **/
const TrashModal = ({ open, onClose, discardedControls, onRestore }) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    TransitionComponent={Fade}
    transitionDuration={300}
  >
    <DialogTitle
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: 'divider',
        pb: 2,
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <DeleteIcon color="error" />
        <Typography variant="h6">Discarded Controls</Typography>
      </Box>
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent sx={{ mt: 2 }}>
      {discardedControls.length > 0 ? (
        <List>
          {discardedControls.map((control, index) => (
            <ListItem
              key={control.controlId || index}
              sx={{
                borderRadius: 1,
                mb: 1,
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <ListItemText
                primary={control.name}
                secondary={`Risk: ${control.riskName}`}
                primaryTypographyProps={{
                  variant: 'body1',
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  variant: 'body2',
                  color: 'text.secondary',
                }}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Restore control" arrow>
                  <IconButton
                    edge="end"
                    onClick={() => onRestore(control)}
                    size="small"
                    sx={{
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    <RestoreIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={200}
          flexDirection="column"
          gap={2}
        >
          <DeleteIcon sx={{ fontSize: 48, color: 'text.disabled' }} />
          <Typography color="text.secondary" variant="body1">
            No discarded controls
          </Typography>
        </Box>
      )}
    </DialogContent>

    <DialogActions
      sx={{
        borderTop: 1,
        borderColor: 'divider',
        px: 3,
        py: 2,
      }}
    >
      <Button onClick={onClose} variant="outlined" startIcon={<CloseIcon />}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

/** =========================
 *   DraggableControl
 ========================= **/
const DraggableControl = ({
  control,
  isSavedControl,
  handleConfirmEdit,
  handleDiscardControl,
  onSaveControlClick,
  handleControlEvaluationToggle,
  modelId,
  assessmentUuid,
  expandedControlEvaluation,
  handleSaveOperation,
  findRiskById,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useDraggable({
    id: control.name,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'grab',
  };

  const [isEditing, setIsEditing] = useState(false);
  const [localText, setLocalText] = useState(control.name);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (!isEditing) {
      setLocalText(control.name);
    }
  }, [control.name, isEditing]);

  function handleEdit() {
    setIsEditing(true);
  }
  function handleChange(e) {
    setLocalText(e.target.value);
  }
  async function handleCheck() {
    await handleConfirmEdit(control, localText, isSavedControl);
    setIsEditing(false);
  }
  const handleEvaluateClick = () => {
    if (!isSavedControl) return;
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <TableCell>
          <Box display="flex" alignItems="center" width="100%">
            {isEditing ? (
              <TextField
                value={localText}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                size="small"
              />
            ) : (
              <Typography style={{ cursor: 'pointer', flexGrow: 1 }}>
                {control.name}
              </Typography>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" gap={1}>
            {/* Edit Icon */}
            {isEditing ? (
              <IconButton onClick={handleCheck}>
                <CheckIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            )}

            {/* Delete Icon */}
            <IconButton onClick={() => handleDiscardControl(findRiskById(control.riskId), control)}>
              <DeleteIcon style={{ color: 'red' }} />
            </IconButton>

            {/* Save Icon */}
            {!isSavedControl && (
              <IconButton
                onClick={() =>
                  onSaveControlClick({ riskId: control.riskId, name: control.riskName }, control)
                }
              >
                <SaveIcon />
              </IconButton>
            )}

            {/* Generate Controls Icon with Tooltip */}
            {isSavedControl && (
              <Tooltip title="Action details" arrow>
                <IconButton onClick={handleEvaluateClick}>
                  <ListAltOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </TableCell>
      </TableRow>

      {/* Drawer for Control Evaluation Form */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        transitionDuration={{ enter: 300, exit: 300 }}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' }, // Responsive widths
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        }}
        aria-labelledby="action-details-title"
      >
        {/* Drawer Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography id="action-details-title" variant="h6">Action Details</Typography>
          <IconButton onClick={handleDrawerClose} aria-label="Close drawer" sx={{ color: 'grey.600', '&:hover': { color: 'primary.main' } }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        {/* Scrollable Content */}
        <Box sx={{ flexGrow: 1, overflowY: 'auto', mt: 2 }}>
          <ControlEvaluationForm
            riskId={control.riskId}
            controlId={control.controlId}
            modelId={modelId}
            assessmentUuid={assessmentUuid}
            handleSaveOperation={handleSaveOperation}
          />
        </Box>
        {/* Drawer Footer */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" onClick={handleDrawerClose} startIcon={<CloseIcon />}>
            Close
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

/** =========================
 *   ActionPlan Component Continued
 ========================= **/
function ActionPlan({ riskFormData, modelId, assessmentUuid, sectionName, narratives }) {
  const dispatch = useDispatch();

  // --- Redux State ---
  const controls = useSelector((state) => state.controls.controls || {}); // Case-specific
  const riskAreaData = riskFormData[sectionName];
  const isLoading = useSelector((state) => state.controls.loading);
  const savedControls = useSelector((state) => state.controls.savedControls || {});
  const discardedControlsObj = useSelector((state) => state.controls.discardedControls || {});
  const discardedControls = useMemo(() => Object.values(discardedControlsObj).flat(), [
    discardedControlsObj,
  ]);

  const sectionRisks = useSelector(
    (state) => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || []
  );

  // --- Local State ---
  const [expandedRiskCard, setExpandedRiskCard] = useState(null);
  const [expandedControlEvaluation, setExpandedControlEvaluation] = useState(null); // For toggling control eval
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [viewMode, setViewMode] = useState('saved'); // 'saved' or 'generated'

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  // DnD
  const [activeControl, setActiveControl] = useState(null);
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));

  // Hooks
  const { isSaving, isSaved, handleSaveOperation } = useSaveOperation();

  // Flattened "Saved" risks for referencing
  const flattenedRisks = useMemo(() => {
    if (!sectionRisks) return [];
    return Object.values(sectionRisks)
      .flat()
      .filter((r) => r.status === 'Saved');
  }, [sectionRisks]);

  // On mount => fetch saved controls, etc.
  useEffect(() => {
    if (modelId && assessmentUuid) {
      dispatch(setSavedControls(modelId, assessmentUuid));
      dispatch(fetchSavedControlEvaluations(modelId, assessmentUuid));
    }
  }, [dispatch, modelId, assessmentUuid]);

  function handleCardClick(riskId) {
    const isExpanded = expandedRiskCard === riskId;
    setExpandedRiskCard(isExpanded ? null : riskId);

    if (!isExpanded) {
      // We are expanding the risk. Check if it has saved controls:
      const haveSaved = (savedControls[riskId]?.length || 0) > 0;
      setViewMode(haveSaved ? 'saved' : 'generated');
    }
  }

  function handleConfirmEdit(control, newName, isSavedControl) {
    if (isSavedControl) {
      // do immediate DB update, then dispatch editSavedControl
      handleUpdateSavedControl(control, newName);
    } else {
      // just do local in-memory rename, e.g. editGeneratedControl
      dispatch(editGeneratedControl(control.riskId, control.controlId, newName));
    }
  }

  async function handleUpdateSavedControl(control, newName) {
    // 1) Construct an updated control
    const updated = { ...control, name: newName };
    // 2) DB update
    try {
      await handleSaveOperation(() =>
        handleSaveControl(
          { riskId: updated.riskId, name: updated.riskName },
          updated
        )
      );
      // 3) Optionally call something like "editSavedControl" in Redux
      dispatch(editSavedControl(updated.riskId, updated.controlId, newName));

      setSnackbarMessage('Saved action updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      setSnackbarMessage(err.message || 'Error updating saved control');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }

  // Toggle control evaluation
  const handleControlEvaluationToggle = (controlId) => {
    setExpandedControlEvaluation((prev) => (prev === controlId ? null : controlId));
  };

  /** ========== Drag/Drop Handlers ========== **/
  const handleDragStart = ({ active }) => {
    const foundControl = findControlByName(active.id);
    setActiveControl(foundControl || null);
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveControl(null);
      return;
    }
    const overId = over.id;
    const draggedControl = findControlByName(active.id);
    if (!draggedControl) {
      setSnackbarMessage('Control not found.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setActiveControl(null);
      return;
    }

    // If user drops onto the floating save icon:
    if (overId === 'floatingSave') {
      dispatch(addSavedControl(draggedControl.riskId, draggedControl));
      setSnackbarMessage('Control saved successfully!');
      setSnackbarSeverity('success');
    } else if (overId === 'discardedControls') {
      dispatch(discardControl(draggedControl, draggedControl.riskId));
      setSnackbarMessage('Control discarded successfully!');
      setSnackbarSeverity('info');
    } else {
      console.error('Invalid drop zone:', overId);
      setSnackbarMessage('Invalid drop zone');
      setSnackbarSeverity('error');
    }

    setSnackbarOpen(true);
    setActiveControl(null);
  };

  const handleDragCancel = () => setActiveControl(null);

  // Find a control by "name"
  const findControlByName = (name) => {
    const allControls = Object.values(controls).flat();
    return allControls.find((ctrl) => ctrl.name === name);
  };

  // Possibly find a risk by ID
  const findRiskById = (riskId) => flattenedRisks.find((r) => r.riskId === riskId);

  /** ========== Original Save/Discard/Edit logic ========== **/
  const handleDiscardControl = (risk, control) => {
    dispatch(discardControl(control, risk?.riskId));
    setSnackbarMessage('Control discarded');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const onSaveControlClick = async (risk, control) => {
    try {
      await handleSaveOperation(() => handleSaveControl(risk, control));
      setSnackbarMessage('Control saved successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage(error.message || 'Error saving control');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSaveControl = async (risk, control) => {
    const { riskId, name: riskName } = risk;
    const modelUuid = modelId;
    const controlName = control.name;
    const controlUuid = control.controlId;

    try {
      const response = await fetch('/.netlify/functions/saveControl', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          modelUuid,
          assessmentUuid,
          riskUuid: riskId,
          controlName,
          ...(controlUuid ? { controlUuid } : {}),
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to save the control');
      }

      const data = await response.json();
      dispatch(
        addSavedControl(riskId, {
          name: controlName,
          controlId: data.controlUuid || controlUuid,
          modelUuid,
          riskName,
        })
      );
      return data;
    } catch (err) {
      console.error('Error saving control:', err);
      throw err;
    }
  };

  /** ========== Generating/Fetching logic ========== **/
  const handleGenerateRiskSpecificActions = (riskId) => {
    const foundRisk = findRiskById(riskId);
    if (!foundRisk) {
      setSnackbarMessage('Risk not found.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    dispatch(
      fetchControls({
        sectionName,
        narratives,
        riskSourceData: riskAreaData,
        sectionRisks: [foundRisk],
      })
    );
  };

  /** =========================
 *   ActionPlan Component Continued
 ========================= **/

  // Snackbar close
  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ position: 'relative', p: 2 }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        modifiers={[restrictToWindowEdges]}
      >
        {/* Header */}
        <Paper sx={{ mb: 4, p: 3, position: 'relative' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Risk Handling Plan</Typography>
            <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
          </Box>
        </Paper>

        {/* Risk List with Internal Tabs */}
        <Paper sx={{ position: 'relative' }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: '70%',
                      fontWeight: 600,
                      backgroundColor: 'grey.50',
                      borderBottom: 2,
                      borderColor: 'divider',
                    }}
                  >
                    Risk Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '15%',
                      fontWeight: 600,
                      backgroundColor: 'grey.50',
                      borderBottom: 2,
                      borderColor: 'divider',
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '15%',
                      fontWeight: 600,
                      backgroundColor: 'grey.50',
                      borderBottom: 2,
                      borderColor: 'divider',
                    }}
                  >
                    Generate
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flattenedRisks.map((risk) => {
                  const riskSpecificCount = controls[risk.riskId]?.length || 0;
                  const savedCountForRisk = savedControls[risk.riskId]?.length || 0;
                  const discardedCountForRisk = discardedControlsObj[risk.riskId]?.length || 0;

                  return (
                    <React.Fragment key={risk.riskId}>
                      <TableRow hover>
                        <TableCell>
                          <Typography>{risk.riskName}</Typography>
                        </TableCell>
                        <TableCell>
                          {/* Optionally, show a Chip or count here */}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Generate Controls" arrow>
                            <IconButton
                              onClick={() => handleCardClick(risk.riskId)}
                              aria-label={
                                expandedRiskCard === risk.riskId
                                  ? 'Collapse'
                                  : 'Generate Controls'
                              }
                            >
                              {expandedRiskCard === risk.riskId ? (
                                <ExpandLessIcon />
                              ) : (
                                <SmartToyOutlinedIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>

                      {/* Expanded Row => Shows Internal Tabs and Controls */}
                      {expandedRiskCard === risk.riskId && (
                        <TableRow>
                          <TableCell colSpan={3} sx={{ p: 0 }}>
                            <Collapse
                              in={expandedRiskCard === risk.riskId}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ p: 3 }}>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    pr: '24px',
                                    gap: 2,
                                    mb: 2,
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{ flexShrink: 0, mr: 2 }}
                                  >
                                    {viewMode === 'saved'
                                      ? 'Saved Actions'
                                      : 'Generated Actions'}
                                  </Typography>
                                  <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FloatingSaveButton
                                      riskSpecificCount={riskSpecificCount}
                                      savedCountForRisk={savedCountForRisk}
                                      viewMode={viewMode}
                                      onToggle={() =>
                                        setViewMode((prev) =>
                                          prev === 'saved'
                                            ? 'generated'
                                            : 'saved'
                                        )
                                      }
                                    />
                                    <FloatingTrashButton
                                      trashCount={discardedCountForRisk}
                                      onClick={() =>
                                        setShowTrashModal(true)
                                      }
                                    />
                                  </Box>
                                </Box>

                                {viewMode === 'generated' && riskSpecificCount === 0 && (
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ mb: 2 }}
                                  >
                                    No controls generated, use the button
                                    above to generate controls.
                                  </Typography>
                                )}

                                {viewMode === 'generated' && (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={<IconWithProgress isLoading={isLoading} />}
                                    disabled={isLoading}
                                    onClick={() => handleGenerateRiskSpecificActions(risk.riskId)}
                                    sx={{ mb: 2 }}
                                  >
                                    {riskSpecificCount === 0 ? 'Generate Actions' : 'Refresh Actions'}
                                  </Button>
                                )}

                                <TableContainer component={Paper} sx={{ mt: 2 }}>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          Control Name
                                        </TableCell>
                                        <TableCell
                                          style={{ fontWeight: 'bold' }}
                                        >
                                          Actions
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {(viewMode === 'saved'
                                        ? savedControls[risk.riskId]
                                        : controls[risk.riskId]
                                      )?.map((ctrl, i) => {
                                        const isSavedControl = viewMode === 'saved';
                                        return (
                                          <DraggableControl
                                            key={ctrl.controlId}
                                            control={ctrl}
                                            isSavedControl={isSavedControl}
                                            handleConfirmEdit={handleConfirmEdit}
                                            handleDiscardControl={handleDiscardControl}
                                            onSaveControlClick={onSaveControlClick}
                                            handleControlEvaluationToggle={handleControlEvaluationToggle}
                                            modelId={modelId}
                                            assessmentUuid={assessmentUuid}
                                            expandedControlEvaluation={expandedControlEvaluation}
                                            handleSaveOperation={handleSaveOperation}
                                            findRiskById={findRiskById}
                                          />
                                        )
                                      })}

                                      {((viewMode === 'saved'
                                        ? savedControls[risk.riskId]
                                        : controls[risk.riskId]
                                      )?.length === 0) && (
                                        <TableRow>
                                          <TableCell colSpan={2} align="center">
                                            <Typography>
                                              {viewMode === 'saved'
                                                ? 'No saved controls.'
                                                : 'No controls generated, use the button above to generate controls.'}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>

                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Drag Overlay */}
        <DragOverlay>
          {activeControl && (
            <Paper
              sx={{
                px: 2,
                py: 1,
                backgroundColor: '#fff',
                boxShadow: 4,
                borderRadius: 1,
                opacity: 0.9,
              }}
            >
              {activeControl.name}
            </Paper>
          )}
        </DragOverlay>

        {/* Trash Modal */}
        <TrashModal
          open={showTrashModal}
          onClose={() => setShowTrashModal(false)}
          discardedControls={discardedControls}
          onRestore={(ctrl) => {
            // Restore logic
            onSaveControlClick({ riskId: ctrl.riskId, name: ctrl.riskName }, ctrl);
            setSnackbarMessage('Control restored successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
          }}
        />

        {/* Snackbar */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DndContext>
    </Box>
  );
}

export default ActionPlan;
// // src/ActionPlan.js

// import React, { useState, useEffect, useMemo } from 'react';
// import { useSelector, useDispatch } from 'react-redux';

// // MUI + Icons
// import {
//   ExpandLess as ExpandLessIcon,
//   Edit as EditIcon,
//   Check as CheckIcon,
//   Save as SaveIcon,
//   ListAltOutlined as ListAltOutlinedIcon,
//   DeleteOutline as DeleteIcon,
//   Restore as RestoreIcon,
//   Close as CloseIcon,
//   SmartToyOutlined as SmartToyOutlinedIcon, // Newly added
// } from '@mui/icons-material';
// import {
//   Box,
//   Paper,
//   Button,
//   Typography,
//   Badge,
//   Table,
//   TableContainer,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
//   Drawer,
//   Divider,
//   Collapse,
//   IconButton,
//   TextField,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   Tooltip,
//   Fade,
// } from '@mui/material';

// // DnDKit
// import {
//   DndContext,
//   useDroppable,
//   useDraggable,
//   useSensor,
//   useSensors,
//   PointerSensor,
//   closestCenter,
//   DragOverlay,
// } from '@dnd-kit/core';
// import { restrictToWindowEdges } from '@dnd-kit/modifiers';
// import { CSS } from '@dnd-kit/utilities';

// // Redux Actions
// import {
//   fetchControls,
//   setSavedControls,
//   fetchSavedControlEvaluations,
//   addSavedControl,
//   discardControl,
//   editGeneratedControl,
//   editSavedControl
// } from './redux/actions/controlActions';

// // Helpers
// import IconWithProgress from './IconWithProgress';
// import AutosaveIndicator from './AutoSaveIndicator';
// import useSaveOperation from './useSaveOperation';
// import ControlEvaluationForm from './ControlEvaluation';

// /** =========================
//  *   Floating Trash Button
//    ========================= **/
// const FloatingTrashButton = ({ trashCount, onClick }) => {
//   const { isOver, setNodeRef } = useDroppable({ id: 'discardedControls' });

//   return (

//       <Tooltip title="View discarded controls" arrow placement="left">
//         <Badge
//         ref={setNodeRef}
//           badgeContent={trashCount}
//           color="error"
//           max={99}
//           overlap="circular"
//           sx={{
//             '& .MuiBadge-badge': {
//               fontSize: '0.75rem',
//               height: '22px',
//               minWidth: '22px',
//               padding: '0 4px',
//               top: '0px',
//               right: '-5px',
//             },
//           }}
//         >
//           <IconButton
//             onClick={onClick}
//             sx={{
//               backgroundColor: isOver ? 'error.light' : 'background.paper',
//               boxShadow: 3,
//               '&:hover': {
//                 backgroundColor: 'action.hover',
//                 transform: 'scale(1.1)',
//               },
//               transition: 'transform 0.2s ease-in-out',
//             }}
//           >
//             <DeleteIcon />
//           </IconButton>
//         </Badge>
//       </Tooltip>
//    // </Box>
//   );
// };

// /** =========================
//  *   Floating Save Button
//    ========================= **/


//    const FloatingSaveButton = ({
//     viewMode,
//     onToggle,
//     riskSpecificCount,
//     savedCountForRisk,
//   }) => {
//     const { isOver, setNodeRef } = useDroppable({ id: 'floatingSave' });
  
//     const isGenerated = viewMode === 'generated';
  
//     // If we are in "generated" mode, we show a Save icon + the savedCount
//     // If we are in "saved" mode, we show a SmartToy icon + the generatedCount
//     const icon = isGenerated ? <SaveIcon /> : <SmartToyOutlinedIcon />;
//     const tooltip = isGenerated
//       ? 'Switch to Saved Controls'
//       : 'Switch to Generate Controls';
  
//     const badgeNumber = isGenerated ? savedCountForRisk : riskSpecificCount;
  
//     return (
//       <Tooltip title={tooltip} arrow placement="left">
//         <Badge
//           ref={setNodeRef}
//           badgeContent={badgeNumber}
//           color="primary"
//           max={99}
//           overlap="circular"
//           sx={{
//             '& .MuiBadge-badge': {
//               fontSize: '0.75rem',
//               height: '22px',
//               minWidth: '22px',
//               padding: '0 4px',
//               top: '0px',
//               right: '-5px',
//             },
//           }}
//         >
//           <IconButton
//             onClick={onToggle}
//             sx={{
//               backgroundColor: isOver ? 'primary.light' : 'background.paper',
//               boxShadow: 3,
//               '&:hover': {
//                 backgroundColor: 'action.hover',
//                 transform: 'scale(1.1)',
//               },
//               transition: 'transform 0.2s ease-in-out',
//             }}
//           >
//             {icon}
//           </IconButton>
//         </Badge>
//       </Tooltip>
//     );
//   };

// /** =========================
//  *   Trash Modal
//    ========================= **/
// const TrashModal = ({ open, onClose, discardedControls, onRestore }) => (
//   <Dialog
//     open={open}
//     onClose={onClose}
//     maxWidth="sm"
//     fullWidth
//     TransitionComponent={Fade}
//     transitionDuration={300}
//   >
//     <DialogTitle
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'space-between',
//         borderBottom: 1,
//         borderColor: 'divider',
//         pb: 2,
//       }}
//     >
//       <Box display="flex" alignItems="center" gap={1}>
//         <DeleteIcon color="error" />
//         <Typography variant="h6">Discarded Controls</Typography>
//       </Box>
//       <IconButton onClick={onClose} size="small">
//         <CloseIcon />
//       </IconButton>
//     </DialogTitle>

//     <DialogContent sx={{ mt: 2 }}>
//       {discardedControls.length > 0 ? (
//         <List>
//           {discardedControls.map((control, index) => (
//             <ListItem
//               key={control.controlId || index}
//               sx={{
//                 borderRadius: 1,
//                 mb: 1,
//                 '&:hover': {
//                   backgroundColor: 'action.hover',
//                 },
//               }}
//             >
//               <ListItemText
//                 primary={control.name}
//                 secondary={`Risk: ${control.riskName}`}
//                 primaryTypographyProps={{
//                   variant: 'body1',
//                   fontWeight: 500,
//                 }}
//                 secondaryTypographyProps={{
//                   variant: 'body2',
//                   color: 'text.secondary',
//                 }}
//               />
//               <ListItemSecondaryAction>
//                 <Tooltip title="Restore control" arrow>
//                   <IconButton
//                     edge="end"
//                     onClick={() => onRestore(control)}
//                     size="small"
//                     sx={{
//                       '&:hover': {
//                         color: 'primary.main',
//                       },
//                     }}
//                   >
//                     <RestoreIcon />
//                   </IconButton>
//                 </Tooltip>
//               </ListItemSecondaryAction>
//             </ListItem>
//           ))}
//         </List>
//       ) : (
//         <Box
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//           minHeight={200}
//           flexDirection="column"
//           gap={2}
//         >
//           <DeleteIcon sx={{ fontSize: 48, color: 'text.disabled' }} />
//           <Typography color="text.secondary" variant="body1">
//             No discarded controls
//           </Typography>
//         </Box>
//       )}
//     </DialogContent>

//     <DialogActions
//       sx={{
//         borderTop: 1,
//         borderColor: 'divider',
//         px: 3,
//         py: 2,
//       }}
//     >
//       <Button onClick={onClose} variant="outlined" startIcon={<CloseIcon />}>
//         Close
//       </Button>
//     </DialogActions>
//   </Dialog>
// );




// function ActionPlan({ riskFormData, modelId, assessmentUuid, sectionName, narratives }) {
//   const dispatch = useDispatch();

//   // --- Redux State ---
//   const controls = useSelector((state) => state.controls.controls || {}); // Case-specific
//   const riskAreaData = riskFormData[sectionName];
//   const isLoading = useSelector((state) => state.controls.loading);
//   const savedControls = useSelector((state) => state.controls.savedControls || {});
//   const discardedControlsObj = useSelector((state) => state.controls.discardedControls || {});
//   const discardedControls = useMemo(() => Object.values(discardedControlsObj).flat(), [
//     discardedControlsObj,
//   ]);

//   const sectionRisks = useSelector(
//     (state) => state.risks.models[modelId]?.riskFormData[sectionName]?.sectionRisks || []
//   );

//   // --- Local State ---
 
//   const [expandedRiskCard, setExpandedRiskCard] = useState(null);
//   const [expandedControlEvaluation, setExpandedControlEvaluation] = useState(null); // For toggling control eval
//   const [showTrashModal, setShowTrashModal] = useState(false);
//   const [viewMode, setViewMode] = useState('saved'); // 'saved' or 'generated'

//   // Snackbar
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');

//   // DnD
//   const [activeControl, setActiveControl] = useState(null);
//   const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));

//   // Hooks
//   const { isSaving, isSaved, handleSaveOperation } = useSaveOperation();

//   // Flattened "Saved" risks for referencing
//   const flattenedRisks = useMemo(() => {
//     if (!sectionRisks) return [];
//     return Object.values(sectionRisks)
//       .flat()
//       .filter((r) => r.status === 'Saved');
//   }, [sectionRisks]);

//   // On mount => fetch saved controls, etc.
//   useEffect(() => {
//     if (modelId && assessmentUuid) {
//       dispatch(setSavedControls(modelId, assessmentUuid));
//       dispatch(fetchSavedControlEvaluations(modelId, assessmentUuid));
//     }
//   }, [dispatch, modelId, assessmentUuid]);


//   function handleCardClick(riskId) {
//     const isExpanded = expandedRiskCard === riskId;
//     setExpandedRiskCard(isExpanded ? null : riskId);
  
//     if (!isExpanded) {
//       // We are expanding the risk. Check if it has saved controls:
//       const haveSaved = (savedControls[riskId]?.length || 0) > 0;
//       setViewMode(haveSaved ? 'saved' : 'generated'); 
//     }
//   }

  
//   function handleConfirmEdit(control, newName, isSavedControl) {
//     if (isSavedControl) {
//       // do immediate DB update, then dispatch editSavedControl
//       handleUpdateSavedControl(control, newName);
//     } else {
//       // just do local in-memory rename, e.g. editGeneratedControl
//       dispatch(editGeneratedControl(control.riskId, control.controlId, newName));
//     }
//   }
  
//   async function handleUpdateSavedControl(control, newName) {
//     // 1) Construct an updated control
//     const updated = { ...control, name: newName };
//     // 2) DB update
//     try {
//       await handleSaveOperation(() =>
//         handleSaveControl(
//           { riskId: updated.riskId, name: updated.riskName },
//           updated
//         )
//       );
//       // 3) Optionally call something like "editSavedControl" in Redux
//       dispatch(editSavedControl(updated.riskId, updated.controlId, newName));
  
//       setSnackbarMessage('Saved action updated successfully!');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       setSnackbarMessage(err.message || 'Error updating saved control');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     }
//   }

//   // Toggle control evaluation
//   const handleControlEvaluationToggle = (controlId) => {
//     setExpandedControlEvaluation((prev) => (prev === controlId ? null : controlId));
//   };

//   /** ========== Drag/Drop Handlers ========== **/
//   const handleDragStart = ({ active }) => {
//     const foundControl = findControlByName(active.id);
//     setActiveControl(foundControl || null);
//   };

//   const handleDragEnd = ({ active, over }) => {
//     if (!over) {
//       setActiveControl(null);
//       return;
//     }
//     const overId = over.id;
//     const draggedControl = findControlByName(active.id);
//     if (!draggedControl) {
//       setSnackbarMessage('Control not found.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       setActiveControl(null);
//       return;
//     }

//     // If user drops onto the floating save icon:
//     if (overId === 'floatingSave') {
//       dispatch(addSavedControl(draggedControl.riskId, draggedControl));
//       setSnackbarMessage('Control saved successfully!');
//       setSnackbarSeverity('success');
//     } else if (overId === 'discardedControls') {
//       dispatch(discardControl(draggedControl, draggedControl.riskId));
//       setSnackbarMessage('Control discarded successfully!');
//       setSnackbarSeverity('info');
//     } else {
//       console.error('Invalid drop zone:', overId);
//       setSnackbarMessage('Invalid drop zone');
//       setSnackbarSeverity('error');
//     }

//     setSnackbarOpen(true);
//     setActiveControl(null);
//   };

//   const handleDragCancel = () => setActiveControl(null);

//   // Find a control by "name"
//   const findControlByName = (name) => {
//     const allControls = Object.values(controls).flat();
//     return allControls.find((ctrl) => ctrl.name === name);
//   };

//   // Possibly find a risk by ID
//   const findRiskById = (riskId) => flattenedRisks.find((r) => r.riskId === riskId);

//   /** ========== Original Save/Discard/Edit logic ========== **/
//   const handleDiscardControl = (risk, control) => {
//     dispatch(discardControl(control, risk?.riskId));
//     setSnackbarMessage('Control discarded');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   const onSaveControlClick = async (risk, control) => {
//     try {
//       await handleSaveOperation(() => handleSaveControl(risk, control));
//       setSnackbarMessage('Control saved successfully!');
//       setSnackbarSeverity('success');
//     } catch (error) {
//       setSnackbarMessage(error.message || 'Error saving control');
//       setSnackbarSeverity('error');
//     } finally {
//       setSnackbarOpen(true);
//     }
//   };

//   const handleSaveControl = async (risk, control) => {
//     const { riskId, name: riskName } = risk;
//     const modelUuid = modelId;
//     const controlName = control.name;
//     const controlUuid = control.controlId;

//     try {
//       const response = await fetch('/.netlify/functions/saveControl', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           modelUuid,
//           assessmentUuid,
//           riskUuid: riskId,
//           controlName,
//           ...(controlUuid ? { controlUuid } : {}),
//         }),
//       });
//       if (!response.ok) {
//         throw new Error('Failed to save the control');
//       }

//       const data = await response.json();
//       dispatch(
//         addSavedControl(riskId, {
//           name: controlName,
//           controlId: data.controlUuid || controlUuid,
//           modelUuid,
//           riskName,
//         })
//       );
//       return data;
//     } catch (err) {
//       console.error('Error saving control:', err);
//       throw err;
//     }
//   };

//   /** ========== Generating/Fetching logic ========== **/
//   const handleGenerateRiskSpecificActions = (riskId) => {
//     const foundRisk = findRiskById(riskId);
//     if (!foundRisk) {
//       setSnackbarMessage('Risk not found.');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }
//     dispatch(
//       fetchControls({
//         sectionName,
//         narratives,
//         riskSourceData: riskAreaData,
//         sectionRisks: [foundRisk],
//       })
//     );
//   };

//   /** =========================
//  *   DraggableControl
//    ========================= **/
// const DraggableControl = ({
//   control,
//   isSavedControl,
//   handleConfirmEdit,
//   handleDiscardControl,
//   onSaveControlClick,
//   handleControlEvaluationToggle,
//   modelId,
//   assessmentUuid,
//   expandedControlEvaluation,
//   handleSaveOperation,
//   findRiskById,
// }) => {
//   const { attributes, listeners, setNodeRef, transform, transition } = useDraggable({
//     id: control.name,
//   });

//   const style = {
//     transform: CSS.Translate.toString(transform),
//     transition,
//     cursor: 'grab',
//   };

//   //const isExpanded = expandedControlEvaluation === control.controlId;
//   const [isEditing, setIsEditing] = useState(false);
//   const [localText, setLocalText] = useState(control.name);
//   const [drawerOpen, setDrawerOpen] = useState(false);
  
//   useEffect(() => {
//     if (!isEditing) {
//       // Only update localText if we’re not actively editing
//       setLocalText(control.name);
//     }
//   }, [control.name, isEditing]);

//   function handleEdit() {
//     setIsEditing(true);
//   }
//   function handleChange(e) {
//     setLocalText(e.target.value);
//   }
//   async function handleCheck() {
//     // 1) We call the parent or dispatch a Redux action to store localText for this control
//     await handleConfirmEdit(control, localText, isSavedControl);
//     // 2) We exit edit mode
//     setIsEditing(false);
//   }
//   const handleEvaluateClick = () => {
//     // Only open drawer if it's a saved control
//     if (!isSavedControl) return;
//     setDrawerOpen(true);
//   };
//   const handleDrawerClose = () => {
//     setDrawerOpen(false);
//   };
 
//   return (
//     <>
//       <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
//         <TableCell>
//           <Box display="flex" alignItems="center" width="100%">
//             {isEditing ? (
//               <TextField
//               value={localText}
//               onChange={handleChange}
//                 fullWidth
//               />
//             ) : (
//               <Typography style={{ cursor: 'pointer', flexGrow: 1 }}>
//                 {control.name}
//               </Typography>
//             )}
//           </Box>
//         </TableCell>
//         <TableCell>
//           <Box display="flex" alignItems="center" gap={1}>
//             {/* Edit Icon */}
//             {isEditing? (
//               <IconButton onClick={handleCheck}>
//                 <CheckIcon />
//               </IconButton>
//             ) : (
//               <IconButton onClick={handleEdit}>
//                 <EditIcon />
//               </IconButton>
//             )}

//             {/* Delete Icon */}
//             <IconButton onClick={() => handleDiscardControl(findRiskById(control.riskId), control)}>
//               <DeleteIcon style={{ color: 'red' }} />
//             </IconButton>

//             {/* Save Icon */}
//             {!isSavedControl && (
//                 <IconButton
//                   onClick={() => 
//                     onSaveControlClick({ riskId: control.riskId, name: control.riskName }, control)
//                   }
//                 >
//                   <SaveIcon />
//                 </IconButton>
//               )}

//             {/* Generate Controls Icon with Tooltip */}
//             {isSavedControl && (
//               <Tooltip title="Action details" arrow>
//                 <IconButton onClick={handleEvaluateClick}>
//                    <ListAltOutlinedIcon />
//                 </IconButton>
//               </Tooltip>
//             )}
//           </Box>
//         </TableCell>
//       </TableRow>

//       {/* Collapsible Control Evaluation Form */}
//       {/* {isSavedControl && (
//         <TableRow>
//           <TableCell colSpan={2} sx={{ p: 0, borderBottom: 'none' }}>
//             <Collapse in={isExpanded} timeout="auto" unmountOnExit>
//               <Box margin={1}>
//                 <Typography variant="body2">Control Evaluation:</Typography>
//                 <ControlEvaluationForm
//                   riskId={control.riskId}
//                   controlId={control.controlId}
//                   modelId={modelId}
//                   assessmentUuid={assessmentUuid}
//                   handleSaveOperation={handleSaveOperation}
//                 />
//               </Box>
//             </Collapse>
//           </TableCell>
//         </TableRow>
//       )} */}
//       <Drawer
//         anchor="right"
//         open={drawerOpen}
//         onClose={handleDrawerClose}
//       >
//         <Box sx={{ width: 420, display: 'flex', flexDirection: 'column', height: '100%' }}>
//           {/* Drawer Header */}
//           <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//             <Typography variant="h6">Action Details</Typography>
//             <IconButton onClick={handleDrawerClose}>
//               <CloseIcon />
//             </IconButton>
//           </Box>
//           <Divider />

//           {/* Drawer Body */}
//           <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
//             <ControlEvaluationForm
//               riskId={control.riskId}
//               controlId={control.controlId}
//               modelId={modelId}
//               assessmentUuid={assessmentUuid}
//               handleSaveOperation={handleSaveOperation}
//             />
//           </Box>

//           {/* Drawer Footer */}
//           <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
//             <Button variant="outlined" onClick={handleDrawerClose}>
//               Close
//             </Button>
//           </Box>
//         </Box>
//       </Drawer>
//     </>
//   );
// };


//   // Snackbar close
//   const handleSnackbarClose = (_, reason) => {
//     if (reason === 'clickaway') return;
//     setSnackbarOpen(false);
//   };

//   return (
//     <Box sx={{ position: 'relative', p: 2 }}>
//       <DndContext
//         sensors={sensors}
//         collisionDetection={closestCenter}
//         onDragStart={handleDragStart}
//         onDragEnd={handleDragEnd}
//         onDragCancel={handleDragCancel}
//         modifiers={[restrictToWindowEdges]}
//       >
//         {/* Header */}
//         <Paper sx={{ mb: 4, p: 3, position: 'relative' }}>
//           <Box display="flex" alignItems="center" justifyContent="space-between">
//             <Typography variant="h6">Risk Handling Plan</Typography>
//             <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
//           </Box>
//         </Paper>

//         {/* Risk List with Internal Tabs */}
//         <Paper sx={{ position: 'relative' }}>
//           <TableContainer>
//             <Table sx={{ minWidth: 650 }}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell
//                     sx={{
//                       width: '70%',
//                       fontWeight: 600,
//                       backgroundColor: 'grey.50',
//                       borderBottom: 2,
//                       borderColor: 'divider',
//                     }}
//                   >
//                     Risk Name
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       width: '15%',
//                       fontWeight: 600,
//                       backgroundColor: 'grey.50',
//                       borderBottom: 2,
//                       borderColor: 'divider',
//                     }}
//                   >
//                     Status
//                   </TableCell>
//                   <TableCell
//                     sx={{
//                       width: '15%',
//                       fontWeight: 600,
//                       backgroundColor: 'grey.50',
//                       borderBottom: 2,
//                       borderColor: 'divider',
//                     }}
//                   >
//                     Generate
//                   </TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {flattenedRisks.map((risk) => {
//                   const riskSpecificCount = controls[risk.riskId]?.length || 0;
//                   const savedCountForRisk = savedControls[risk.riskId]?.length || 0;
//                   const discardedCountForRisk = discardedControlsObj[risk.riskId]?.length || 0;

//                   return (
//                     <React.Fragment key={risk.riskId}>
//                       <TableRow hover>
//                         <TableCell>
//                           <Typography>{risk.riskName}</Typography>
//                         </TableCell>
//                         <TableCell>
//                           {/* Optionally, show a Chip or count here */}
//                         </TableCell>
//                         <TableCell>
//                           <Tooltip title="Generate Controls" arrow>
//                             <IconButton
//                               onClick={() => handleCardClick(risk.riskId)}
//                               aria-label={
//                                 expandedRiskCard === risk.riskId
//                                   ? 'Collapse'
//                                   : 'Generate Controls'
//                               }
//                             >
//                               {expandedRiskCard === risk.riskId ? (
//                                 <ExpandLessIcon />
//                               ) : (
//                                 <SmartToyOutlinedIcon />
//                               )}
//                             </IconButton>
//                           </Tooltip>
//                         </TableCell>
//                       </TableRow>

//                       {/* Expanded Row => Shows Internal Tabs and Controls */}
//                       {expandedRiskCard === risk.riskId && (
//                         <TableRow>
//                           <TableCell colSpan={3} sx={{ p: 0 }}>
//                             <Collapse
//                               in={expandedRiskCard === risk.riskId}
//                               timeout="auto"
//                               unmountOnExit
//                             >
//                               <Box sx={{ p: 3 }}>

//                                     <Box
//                                       sx={{
//                                         display: 'flex',
//                                         alignItems: 'center',
//                                         justifyContent: 'space-between',
//                                         pr: '24px',
//                                         gap: 2,
//                                         mb: 2,
//                                       }}
//                                     >
//                                     <Typography
//                                       variant="h6"
//                                       sx={{ flexShrink: 0, mr: 2 }}
//                                     >
//                                       {viewMode === 'saved'
//                                         ? 'Saved Actions'
//                                         : 'Generated Actions'}
//                                     </Typography>
//                                     <Box sx={{ display: 'flex', gap: 2 }}>
//                                       <FloatingSaveButton
//                                         riskSpecificCount={riskSpecificCount}
//                                         savedCountForRisk={savedCountForRisk}
//                                         viewMode={viewMode}
//                                         onToggle={() =>
//                                           setViewMode((prev) =>
//                                             prev === 'saved'
//                                               ? 'generated'
//                                               : 'saved'
//                                           )
//                                         }
//                                       />
//                                       <FloatingTrashButton
//                                         trashCount={discardedCountForRisk}
//                                         onClick={() =>
//                                           setShowTrashModal(true)
//                                         }
//                                       />
//                                     </Box>
//                                   </Box>

//                                   {viewMode === 'generated' &&
//                                     riskSpecificCount === 0 && (
//                                       <Typography
//                                         variant="body2"
//                                         color="text.secondary"
//                                         sx={{ mb: 2 }}
//                                       >
//                                         No controls generated, use the button
//                                         above to generate controls.
//                                       </Typography>
//                                     )}

                            
//                                   {viewMode === 'generated' && (
//                                         <Button
//                                           variant="outlined"
//                                           size="small"
//                                           startIcon={<IconWithProgress isLoading={isLoading} />}
//                                           disabled={isLoading}
//                                           onClick={() => handleGenerateRiskSpecificActions(risk.riskId)}
//                                           sx={{ mb: 2 }}
//                                         >
//                                           {riskSpecificCount === 0 ? 'Generate Actions' : 'Refresh Actions'}
//                                         </Button>
//                                       )}

//                                   <TableContainer component={Paper} sx={{ mt: 2 }}>
//                                     <Table>
//                                       <TableHead>
//                                         <TableRow>
//                                           <TableCell
//                                             style={{ fontWeight: 'bold' }}
//                                           >
//                                             Control Name
//                                           </TableCell>
//                                           <TableCell
//                                             style={{ fontWeight: 'bold' }}
//                                           >
//                                             Actions
//                                           </TableCell>
//                                         </TableRow>
//                                       </TableHead>
//                                       <TableBody>
//                                         {(viewMode === 'saved'
//                                           ? savedControls[risk.riskId]
//                                           : controls[risk.riskId]
//                                         )?.map((ctrl, i) => {
//                                        const isSavedControl = viewMode === 'saved';
//                                           return(
//                                           <DraggableControl
//                                             key={ctrl.controlId}
//                                             control={ctrl}
//                                             isSavedControl={isSavedControl}
//                                             handleConfirmEdit={handleConfirmEdit}
//                                             handleDiscardControl={
//                                               handleDiscardControl
//                                             }
//                                             onSaveControlClick={
//                                               onSaveControlClick
//                                             }
//                                             handleControlEvaluationToggle={
//                                               handleControlEvaluationToggle
//                                             }
//                                             modelId={modelId}
//                                             assessmentUuid={assessmentUuid}
//                                             expandedControlEvaluation={
//                                               expandedControlEvaluation
//                                             }
//                                             handleSaveOperation={
//                                               handleSaveOperation
//                                             }
//                                             findRiskById={findRiskById}
//                                           />
//                                           )
//                                         })}

//                                         {((viewMode === 'saved'
//                                           ? savedControls[risk.riskId]
//                                           : controls[risk.riskId]
//                                         )?.length === 0) && (
//                                           <TableRow>
//                                             <TableCell colSpan={2} align="center">
//                                               <Typography>
//                                                 {viewMode === 'saved'
//                                                   ? 'No saved controls.'
//                                                   : 'No controls generated, use the button above to generate controls.'}
//                                               </Typography>
//                                             </TableCell>
//                                           </TableRow>
//                                         )}
//                                       </TableBody>
//                                     </Table>
//                                   </TableContainer>
                                
//                               </Box>
//                             </Collapse>
//                           </TableCell>
//                         </TableRow>
//                       )}
//                     </React.Fragment>
//                   );
//                 })}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Paper>

//         {/* Drag Overlay */}
//         <DragOverlay>
//           {activeControl && (
//             <Paper
//               sx={{
//                 px: 2,
//                 py: 1,
//                 backgroundColor: '#fff',
//                 boxShadow: 4,
//                 borderRadius: 1,
//                 opacity: 0.9,
//               }}
//             >
//               {activeControl.name}
//             </Paper>
//           )}
//         </DragOverlay>

//         {/* Trash Modal */}
//         <TrashModal
//           open={showTrashModal}
//           onClose={() => setShowTrashModal(false)}
//           discardedControls={discardedControls}
//           onRestore={(ctrl) => {
//             // Your restore logic
//             onSaveControlClick({ riskId: ctrl.riskId, name: ctrl.riskName }, ctrl);
//             setSnackbarMessage('Control restored successfully');
//             setSnackbarSeverity('success');
//             setSnackbarOpen(true);
//           }}
//         />

//         {/* Snackbar */}
//         <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//         >
//           <Alert
//             onClose={handleSnackbarClose}
//             severity={snackbarSeverity}
//             sx={{ width: '100%' }}
//           >
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </DndContext>
//     </Box>
//   );
// }

// export default ActionPlan;
