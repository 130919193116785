// src/Compliance.js

import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Paper,
  Typography,
  Button,
  Drawer,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  Alert,
  IconButton,
  Tooltip,
  Collapse,
  Chip,
} from '@mui/material';
import {
  Add as AddIcon,
  PlaylistAdd as PlaylistAddIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  ListAltOutlined as ListAltOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

import { fetchBaselineControls, saveBaselineControl  } from './redux/actions/complianceActions'; 
import IconWithProgress from './IconWithProgress';
import { useAuth0 } from '@auth0/auth0-react';
import useSaveOperation from './useSaveOperation';
import AutosaveIndicator from './AutoSaveIndicator'; 
import BaselineControlEvaluationForm from './BaselineControlEvaluation'

/**
 * Aggregates all saved risks from the provided riskFormData.
 *
 * @param {Object} riskFormData - The comprehensive risk form data containing multiple sections.
 * @returns {Array} - An array of saved risk objects with their respective section and risk source.
 */
function aggregateRisks(riskFormData) {
  const aggregatedRisks = [];

  // Iterate over each section in riskFormData
  Object.entries(riskFormData).forEach(([sectionName, sectionData]) => {
    const { riskSources, sectionRisks } = sectionData;

    // Ensure both riskSources and sectionRisks exist
    if (riskSources && sectionRisks) {
      riskSources.forEach((riskSource) => {
        const { name: riskSourceName } = riskSource;

        // Retrieve risks associated with the current risk source
        const risks = sectionRisks[riskSourceName] || [];

        risks.forEach((risk) => {
          const {
            riskId,
            riskName,
            category,
            type,
            sourceFile,
            causes,
            status,
            owner,
          } = risk;

          // **Filter out risks that are not "Saved"**
          if (status !== 'Saved') return;

          // Construct the aggregated risk object
          const aggregatedRisk = {
            riskId: riskId || null, // Handle cases where riskId might be undefined
            riskName,
            category,
            type,
            sourceFile,
            causes,
            status,
            owner: owner || null, // Handle cases where owner might be undefined
            section: sectionName,
            riskSource: riskSourceName,
          };

          aggregatedRisks.push(aggregatedRisk);
        });
      });
    }
  });

  return aggregatedRisks;
}

/**
 * Converts a string to Title Case.
 *
 * @param {string} str - The string to convert.
 * @returns {string} - The Title Cased string.
 */
const toTitleCase = (str) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

/**
 * Compliance Component
 *
 * @param {Object} props
 * @param {string} props.modelId - The ID of the model.
 * @param {string} props.assessmentUuid - The UUID of the assessment.
 * @param {Object} props.riskFormData - The entire risk form data containing multiple sections.
 * @param {Object} props.narratives - The narratives associated with each section.
 */
function Compliance({ modelId, assessmentUuid, riskFormData, narratives }) {
  const dispatch = useDispatch();
  const { baselineControls, loading, error } = useSelector((state) => state.compliance);
  const { user } = useAuth0();
  const { isSaving, isSaved, handleSaveOperation } = useSaveOperation();

  // Snackbar State
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Aggregated Risks
  const aggregatedRisks = useMemo(() => aggregateRisks(riskFormData), [riskFormData]);

    // --- Evaluation Drawer State for Baseline Controls ---
    const [evaluationDrawerOpen, setEvaluationDrawerOpen] = useState(false);
    const [selectedBaselineControlId, setSelectedBaselineControlId] = useState(null);
 
 //State for policy expand
    const [expandedPolicyDomain, setExpandedPolicyDomain] = useState(null);
    const [expandedRisks, setExpandedRisks] = useState({});   /**
   * Handler to generate baseline controls.
   * This function aggregates risks and dispatches the action to fetch baseline controls.
   */
  const handleGenerateControls = async () => {
    if (modelId && aggregatedRisks.length > 0 && narratives) {
      try {
        await dispatch(fetchBaselineControls(modelId, assessmentUuid, aggregatedRisks, narratives));
        setSnackbarMessage('Baseline controls fetched successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (err) {
        setSnackbarMessage('Failed to fetch baseline controls.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Insufficient data to generate baseline controls.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  /**
   * Groups controls by their associated policy domain and risk.
   *
   * @returns {Object} - An object where each key is a policy domain and the value is an object of risks with their controls.
   */
  const groupedData = useMemo(() => {
    const groups = {};

    baselineControls.forEach((control) => {
      const policyDomain = control.namespace || 'General'; // Default to 'General' if namespace is undefined
      const riskId = control.riskId;
      const risk = aggregatedRisks.find((r) => r.riskId === riskId);

      if (!risk) return; // Skip if risk not found

      if (!groups[policyDomain]) {
        groups[policyDomain] = {};
      }

      if (!groups[policyDomain][riskId]) {
        groups[policyDomain][riskId] = {
          riskName: risk.riskName,
          controls: [],
        };
      }

      groups[policyDomain][riskId].controls.push(control);
    });

    return groups;
  }, [baselineControls, aggregatedRisks]);

  const handleAddToBaseline = async (control) => {
    // We wrap the actual saving function with handleSaveOperation from useSaveOperation
    await handleSaveOperation(async () => {
      // Build data to pass to Netlify function
      const payload = {
        controlUuid: control.controlUuid,
        riskId: control.riskId,
        modelUuid: modelId,
        assessmentUuid,
        controlName: control.control,
        createdBy: user.email,   // pass user info
      };
      // Dispatch the thunk
      await dispatch(saveBaselineControl(payload));
    });
    // Optionally show a separate success if needed
    setSnackbarMessage('Added to baseline!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };
  
  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleOpenEvaluation = (controlId) => {
    setSelectedBaselineControlId(controlId);
    setEvaluationDrawerOpen(true);
  };

  const handleCloseEvaluation = () => {
    setEvaluationDrawerOpen(false);
    setSelectedBaselineControlId(null);
  };

  // State to manage which policy domain row is expanded

  const toggleExpandPolicyDomain = (policyDomain) => {
    setExpandedPolicyDomain((prev) => (prev === policyDomain ? null : policyDomain));
  };

  // State to manage expanded Risks


  const toggleExpandRisk = (riskId) => {
    setExpandedRisks((prev) => ({
      ...prev,
      [riskId]: !prev[riskId],
    }));
  };
  return (
    <Box sx={{ p: 2 }}>
      {/* Header */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6">Compliance Assessment</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Risk-based gap analysis against policies and procedures.
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleGenerateControls}
            disabled={loading}
            startIcon={<IconWithProgress isLoading={loading} size={20} />}
          >
            {loading ? 'Generating...' : 'Generate Compliance Report'}
          </Button>
          {/* Show AutosaveIndicator on the right to reflect global saving */}
          <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
        </Box>
      </Paper>

      <TableContainer component={Paper}>
        <Table>
          {/* Table Head */}
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Policy Domain</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Affected Risks</Typography></TableCell>
              <TableCell><Typography variant="subtitle1" fontWeight="bold">Total Gaps</Typography></TableCell>
              <TableCell align="right"><Typography variant="subtitle1" fontWeight="bold">Actions</Typography></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(groupedData).map(([policyDomain, risks]) => {
              // Calculate number of risks & total gaps
              const numberOfRisks = Object.keys(risks).length;
              const totalGaps = Object.values(risks).reduce((acc, r) => acc + r.controls.length, 0);

              return (
                <React.Fragment key={policyDomain}>
                  {/* Policy Domain Row */}
                  <TableRow hover>
                    <TableCell>
                      <Typography>{toTitleCase(policyDomain)}</Typography>
                    </TableCell>
                    <TableCell>
                      <Chip label={`${numberOfRisks}`} color="primary" />
                    </TableCell>
                    <TableCell>
                      <Chip label={`${totalGaps}`} color="secondary" />
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={expandedPolicyDomain === policyDomain ? 'Collapse Risks' : 'View Risks'} arrow>
                        <IconButton onClick={() => toggleExpandPolicyDomain(policyDomain)}>
                          {expandedPolicyDomain === policyDomain ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>

                  {/* Collapsible Row for Risks */}
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                      <Collapse in={expandedPolicyDomain === policyDomain} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          {Object.keys(risks).length > 0 ? (
                            <Table size="small" aria-label="risks">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ pl: 4 }}>
                                    <Typography variant="subtitle2" fontWeight="bold">Risk Name</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography variant="subtitle2" fontWeight="bold">Identified Gaps</Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Typography variant="subtitle2" fontWeight="bold">Actions</Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {Object.entries(risks).map(([riskId, { riskName, controls }]) => {
                                  const identifiedGaps = controls.length;

                                  return (
                                    <React.Fragment key={riskId}>
                                      {/* Risk Row */}
                                      <TableRow hover>
                                        <TableCell sx={{ pl: 6 }}>
                                          <Typography>{riskName}</Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Chip label={`${identifiedGaps}`} color="warning" />
                                        </TableCell>
                                        <TableCell align="right">
                                          <Tooltip
                                            title={expandedRisks[riskId] ? 'Collapse Controls' : 'View Controls'}
                                            arrow
                                          >
                                            <IconButton onClick={() => toggleExpandRisk(riskId)}>
                                              {expandedRisks[riskId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>

                                      {/* Collapsible Section for Controls */}
                                      <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                                          <Collapse in={expandedRisks[riskId]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                              {controls && controls.length > 0 ? (
                                                <Table size="small" aria-label="controls">
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell sx={{ pl: 8 }}>
                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                          Control Name
                                                        </Typography>
                                                      </TableCell>
                                                      <TableCell>
                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                          Actions
                                                        </Typography>
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>

                                                  <TableBody>
                                                    {controls.map((control) => (
                                                      <TableRow key={control.controlUuid || control.controlId}>
                                                        <TableCell sx={{ pl: 10 }}>
                                                          {/* noWrap ensures no line break */}
                                                          <Typography noWrap title={control.control}>
                                                            {control.control}
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                          <Box sx={{ display: 'flex', gap: 1 }}>
                                                            <Tooltip title="Add to Action Plan" arrow>
                                                              <IconButton
                                                                onClick={() => handleAddToBaseline(control)}
                                                                size="small"
                                                              >
                                                                <AddIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Add to Baseline" arrow>
                                                              <IconButton
                                                                onClick={() => handleAddToBaseline(control)}
                                                                size="small"
                                                              >
                                                                <PlaylistAddIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit Control" arrow>
                                                              <IconButton
                                                                onClick={() => {
                                                                  // e.g., open edit dialog
                                                                }}
                                                                size="small"
                                                              >
                                                                <EditIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Evaluate Baseline Control" arrow>
                                                              <IconButton onClick={() => handleOpenEvaluation(control.controlUuid)}>
                                                                <ListAltOutlinedIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </Box>
                                                        </TableCell>
                                                      </TableRow>
                                                    ))}
                                                  </TableBody>
                                                </Table>
                                              ) : (
                                                <Typography color="text.secondary">
                                                  No controls available for this risk.
                                                </Typography>
                                              )}
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          ) : (
                            <Typography color="text.secondary">
                              No risks available for this policy domain.
                            </Typography>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* If no baseline controls are present */}
      {baselineControls.length === 0 && !loading && !error && (
        <Box sx={{ mt: 2 }}>
          <Typography>No baseline compliance generated yet.</Typography>
        </Box>
      )}

      {/* If an error occurs */}
      {error && (
        <Box sx={{ mt: 2 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
 <Drawer
        anchor="right"
        open={evaluationDrawerOpen}
        onClose={handleCloseEvaluation}
        transitionDuration={{ enter: 300, exit: 300 }}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
        }}
        aria-labelledby="baseline-evaluation-title"
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography id="baseline-evaluation-title" variant="h6">
            Baseline Control Evaluation
          </Typography>
          <IconButton
            onClick={handleCloseEvaluation}
            aria-label="Close drawer"
            sx={{ color: 'grey.600', '&:hover': { color: 'primary.main' } }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, overflowY: 'auto', mt: 2 }}>
          {selectedBaselineControlId && (
            <BaselineControlEvaluationForm
              baselineControlId={selectedBaselineControlId}
              modelId={modelId}
              assessmentUuid={assessmentUuid}
              handleSaveOperation={handleSaveOperation}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" onClick={handleCloseEvaluation} startIcon={<CloseIcon />}>
            Close
          </Button>
        </Box>
      </Drawer>


      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Compliance;
