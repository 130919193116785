// src/redux/reducers/complianceReducer.js

import {
    FETCH_BASELINE_CONTROLS_START,
    FETCH_BASELINE_CONTROLS_SUCCESS,
    FETCH_BASELINE_CONTROLS_FAILURE,
    SAVE_BASELINE_CONTROL_START,
    SAVE_BASELINE_CONTROL_SUCCESS,
    SAVE_BASELINE_CONTROL_FAILURE,
    UPDATE_BASELINE_CONTROL_EVALUATION_FIELD
  } from '../actions/complianceActions.js';
  
  const initialState = {
    baselineControls: [],
    baselineControlEvaluations: {},
    loading: false,
    error: null,
    savingControlUuid: null
  };
  
  const complianceReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BASELINE_CONTROLS_START:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_BASELINE_CONTROLS_SUCCESS:
        return {
          ...state,
          loading: false,
          baselineControls: action.payload,
        };
      case FETCH_BASELINE_CONTROLS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };

        case SAVE_BASELINE_CONTROL_START:
          return {
            ...state,
            savingControlUuid: action.payload, // the controlUuid being saved
            error: null,
          };
        case SAVE_BASELINE_CONTROL_SUCCESS: {
          // We need to find the control in baselineControls with matching controlUuid and update status => "Saved"
          const { controlUuid, status } = action.payload; // plus other props
          const updatedControls = state.baselineControls.map((ctrl) => {
            if (ctrl.controlUuid === controlUuid) {
              return {
                ...ctrl,
                status,
                // also update other fields if needed, e.g. ctrl name
                control: action.payload.controlName ?? ctrl.control,
              };
            }
            return ctrl;
          });
          return {
            ...state,
            baselineControls: updatedControls,
            savingControlUuid: null,
          };
        }
        case SAVE_BASELINE_CONTROL_FAILURE: {
          // Possibly handle error for a specific control
          return {
            ...state,
            savingControlUuid: null,
            error: action.payload.error,
          };
        }
        case UPDATE_BASELINE_CONTROL_EVALUATION_FIELD: {
          const { baselineControlId, fieldName, value } = action.payload;
          console.log ('payload to save baseline ctrl eval', action.payload)
          const currentData = state.baselineControlEvaluations[baselineControlId] || {};
          return {
            ...state,
            baselineControlEvaluations: {
              ...state.baselineControlEvaluations,
              [baselineControlId]: {
                ...currentData,
                [fieldName]: value,
              },
            },
          };
        }
      default:
        return state;
    }
  };
  
  export default complianceReducer;