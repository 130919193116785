

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Box,
  Collapse,
  Button,
  Tooltip,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Save as SaveIcon,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

import {
  editControl,
  discardControl,
  addSavedControl,
  refreshSingleRiskControls,
} from './redux/actions/controlActions';
import useSaveOperation from './useSaveOperation';
import AutosaveIndicator from './AutoSaveIndicator';
import ControlEvaluationForm from './ControlEvaluation';
import IconWithProgress from './IconWithProgress';

const ControlsList = ({
  risk,
  modelId,
  assessmentUuid,
  handleSaveOperation,
  category,
  sectionName,
  sectionState,
}) => {
  const dispatch = useDispatch();
  const [editingControl, setEditingControl] = useState(null);
  const [isGeneratingControls, setIsGeneratingControls] = useState(false);
  const [openControl, setOpenControl] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const { isSaving, isSaved } = useSaveOperation();

  const controls = useSelector((state) => {
    const savedControls = state.controls.savedControls || {};
    const generatedControls = state.controls.controls || {};
    const riskId = risk.riskId;

    return [
      ...(savedControls[riskId] || []),
      ...(generatedControls[riskId] || []),
    ];
  });

  const handleSaveControlToBackend = async (control) => {
    const modelUuid = modelId;
    const controlName = control.name;
    const controlUuid = control.controlId;

    const body = JSON.stringify({
      modelUuid,
      assessmentUuid,
      riskUuid: risk.riskId,
      controlName,
      ...(controlUuid ? { controlUuid } : {}),
    });

    const response = await fetch('/.netlify/functions/saveControl', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => {});
      throw new Error(errorData?.error || 'Failed to save the control');
    }

    const responseData = await response.json();
    dispatch(
      addSavedControl(risk.riskId, {
        name: controlName,
        controlId: responseData.controlUuid || controlUuid,
        modelUuid,
        riskName: control.riskName,
      })
    );
  };

  const handleEditClick = (control, index) => {
    setEditingControl({ control, index });
  };

  const handleEditSave = async (control, index) => {
    // Ensure control has a controlId before saving
    if (!control.controlId) {
      setSnackbarMessage('Please save the control before editing.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // Wrap the whole save operation
    await handleSaveOperation(async () => {
      dispatch(editControl(control, index, risk.riskId));
      await handleSaveControlToBackend(control);
      setEditingControl(null);
      setSnackbarMessage('Control saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    });
  };

  const handleSaveControl = async (control) => {
    // If the control doesn't have a controlId, it's unsaved. Save it first.
    if (!control.controlId) {
      try {
        await handleSaveOperation(async () => {
          await handleSaveControlToBackend(control);
          setSnackbarMessage('Control saved successfully!');
          setSnackbarSeverity('success');
        });
      } catch (error) {
        setSnackbarMessage(error.message || 'Error saving control');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    } else {
      // If control is already saved, you might not need to do anything or handle differently
      setSnackbarMessage('Control is already saved.');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    }
  };

  const handleDiscardControl = (control) => {
    dispatch(discardControl(control, risk.riskId));
  };

  const handleGenerateControls = async () => {
    setIsGeneratingControls(true);
    try {
      await dispatch(
        refreshSingleRiskControls(
          sectionName,
          sectionState,
          risk.riskName,
          risk.riskId,
        )
      );
      setSnackbarMessage('Controls generated successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error generating controls:', error);
      setSnackbarMessage('Failed to generate controls.');
      setSnackbarSeverity('error');
    } finally {
      setIsGeneratingControls(false);
      setSnackbarOpen(true);
    }
  };

  const handleToggleControl = (controlId) => {
    setOpenControl((prev) => ({
      ...prev,
      [controlId]: !prev[controlId],
    }));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6">Controls</Typography>
        <AutosaveIndicator isSaving={isSaving} isSaved={isSaved} />
      </Box>
      {controls.length === 0 ? (
        <Box>
          <Typography>No controls generated for this risk.</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleGenerateControls}
            disabled={isGeneratingControls}
            startIcon={<IconWithProgress isLoading={isGeneratingControls} />}
            sx={{ mt: 2 }}
          >
            {isGeneratingControls ? 'Generating...' : 'Generate Controls'}
          </Button>
        </Box>
      ) : (
        <Table component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>Control Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controls.map((control, index) => (
              <React.Fragment key={control.controlId || index}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => handleToggleControl(control.controlId)}>
                      {openControl[control.controlId] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    {editingControl && editingControl.index === index ? (
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={editingControl.control.name}
                        onChange={(e) =>
                          setEditingControl({
                            ...editingControl,
                            control: { ...editingControl.control, name: e.target.value },
                          })
                        }
                      />
                    ) : (
                      <Typography variant="body1" component="span">
                        {control.name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{control.status}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {/* Conditionally render Edit and Check buttons based on edit mode */}
                      {editingControl && editingControl.index === index ? (
                        <Tooltip title="Save Control">
                          <IconButton
                            onClick={() => handleEditSave(editingControl.control, index)}
                            aria-label="save control"
                            disabled={isSaving}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <>
                          {control.controlId ? (
                            // If control is saved, show Edit button
                            <Tooltip title="Edit Control">
                              <IconButton
                                onClick={() => handleEditClick(control, index)}
                                aria-label="edit control"
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            // If control is unsaved, show Save button and disable Edit
                            <>
                              <Tooltip title="Control must be saved before editing">
                                <span>
                                  <IconButton
                                    onClick={() => {}}
                                    aria-label="edit control"
                                    disabled
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <Tooltip title="Save Control">
                                <IconButton
                                  onClick={() => handleSaveControl(control)}
                                  aria-label="save control"
                                  disabled={isSaving}
                                >
                                  <SaveIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                      <Tooltip title="Delete Control">
                        <IconButton onClick={() => handleDiscardControl(control)}>
                          <DeleteIcon style={{ color: 'red' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={openControl[control.controlId]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <ControlEvaluationForm
                          riskId={risk.riskId}
                          controlId={control.controlId}
                          modelId={modelId}
                          assessmentUuid={assessmentUuid}
                          handleSaveOperation={handleSaveOperation}
                        />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ControlsList;
