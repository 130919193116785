import controlReducer from './controlReducer.js';
import { combineReducers } from 'redux';
import themeReducer from './themeReducer.js';
import riskReducer from './riskReducer.js';
import securityReducer from './securityReducer.js';
import contextReducer from './contextReducer.js';
import cloudModelReducer from './cloudModelsReducer.js';
import configReducer from './configReducer.js';
import complianceReducer from './complianceReducer.js';


const rootReducer = combineReducers({
    themes: themeReducer,
    risks: riskReducer,
    controls: controlReducer,
    compliance:complianceReducer,
    security:securityReducer,
    context: contextReducer,
    cloudModels:cloudModelReducer,
    config:configReducer

});

export default rootReducer;
