// src/redux/actions/complianceActions.js
import { v4 as uuidv4 } from 'uuid';
// Baseline Control Action Types
export const FETCH_BASELINE_CONTROLS_START = 'FETCH_BASELINE_CONTROLS_START';
export const FETCH_BASELINE_CONTROLS_SUCCESS = 'FETCH_BASELINE_CONTROLS_SUCCESS';
export const FETCH_BASELINE_CONTROLS_FAILURE = 'FETCH_BASELINE_CONTROLS_FAILURE';
export const SAVE_BASELINE_CONTROL_START = 'SAVE_BASELINE_CONTROL_START';
export const SAVE_BASELINE_CONTROL_SUCCESS = 'SAVE_BASELINE_CONTROL_SUCCESS';
export const SAVE_BASELINE_CONTROL_FAILURE = 'SAVE_BASELINE_CONTROL_FAILURE';

export const UPDATE_BASELINE_CONTROL_EVALUATION_FIELD =
  'UPDATE_BASELINE_CONTROL_EVALUATION_FIELD';


// Action Creators
export const fetchBaselineControlsStart = () => ({
  type: FETCH_BASELINE_CONTROLS_START,
});

export const fetchBaselineControlsSuccess = (baselineControls) => ({
  type: FETCH_BASELINE_CONTROLS_SUCCESS,
  payload: baselineControls,
});

export const fetchBaselineControlsFailure = (error) => ({
  type: FETCH_BASELINE_CONTROLS_FAILURE,
  payload: error,
});

// Thunk to Fetch Baseline Controls
export const fetchBaselineControls = (modelId,assessmentUuid, risks, narratives) => async (dispatch) => {
  dispatch(fetchBaselineControlsStart());
  try {
    const body = JSON.stringify({ modelId,assessmentUuid, risks, narratives });
    const response = await fetch('/.netlify/functions/generateControlBaseline', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    if (!response.ok) throw new Error(`Failed to generate baseline controls: ${response.statusText}`);

    const data = await response.json();
    const generatedControls = data.baselineControls.map((ctrl) => ({
      ...ctrl,
      status: 'Generated',
      controlUuid: ctrl.controlUuid || uuidv4(), 
    }));
    dispatch(fetchBaselineControlsSuccess(generatedControls));
  } catch (error) {
    dispatch(fetchBaselineControlsFailure(error.message));
  }
};

//Save

export const saveBaselineControl = (controlData) => async (dispatch) => {
  dispatch({ type: SAVE_BASELINE_CONTROL_START, payload: controlData.controlUuid });
  try {
    const response = await fetch('/.netlify/functions/saveBaselineControl', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(controlData),
    });
    if (!response.ok) {
      throw new Error('Failed to save baseline control');
    }
    const data = await response.json();
    // data might return {message, controlUuid, controlName} upon success
    dispatch({
      type: SAVE_BASELINE_CONTROL_SUCCESS,
      payload: {
        ...data,
        status: 'Saved',
      },
    });
  } catch (error) {
    dispatch({
      type: SAVE_BASELINE_CONTROL_FAILURE,
      payload: {
        error: error.message,
        controlUuid: controlData.controlUuid,
      },
    });
  }
};

export const updateBaselineControlEvaluationField = (
  baselineControlId,
  fieldName,
  value
) => ({
  type: UPDATE_BASELINE_CONTROL_EVALUATION_FIELD,
  payload: { baselineControlId, fieldName, value },
});